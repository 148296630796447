.icon-bar {
  border-bottom: 2px $white solid;
  display: block;
  width:1.5rem;
  height:1px;
  margin-top:0.35rem;
  margin-bottom:0.35rem;
}
.pdf {

}