@import "~slick-carousel/slick/slick";

$slick-font-path: "../fonts/" !default;
$slick-font-family: "Montserrat" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: #818891 !default;
$slick-dot-color: #c6cbd3 !default;
$slick-dot-color-active: #ee1846 !default;
$slick-prev-character: "\2329" !default;
$slick-next-character: "\232A" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.5 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 80px;
  width: 40px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: #e7e7e7;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
    //background: transparent;
    color: #818891;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  z-index: 999;
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-family: $slick-font-family;
    font-size: 40px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: 5%;
  @media (min-width: map_get($grid-breakpoints, lg)) {
    left: 15%;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: 5%;
  @media (min-width: map_get($grid-breakpoints, lg)) {
    right: 15%;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

.slick-dots {
  position: absolute;
  bottom: 25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    //height: 20px;
    //width: 20px;
    margin: 0 1px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 12px;
      width: 12px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          //opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 10px;
        height: 10px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        background-color: $slick-dot-color;
        //opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      background-color: $slick-dot-color-active;
      //opacity: $slick-opacity-default;
    }
  }
}