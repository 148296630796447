.footer-map {

  //height:100%;
  height: 400px;
  width: 100%; min-width: 100%;
  display: inline-block;
  &>* {
    height: 100%;
  }
}
footer {
  .phones {
    font-size: 1rem;
    font-weight: 500;
  }
  .email {
    font-size: 1rem;
    font-weight: 500;
  }
  .social {
    font-size: 1.5rem;
  }
  .link {
    font-size: 1rem;
    font-weight: 500;
  }
 .copyright {
   font-size: 0.7rem;
   @media (min-width: map_get($grid-breakpoints,'sm')) {
     font-size: 0.8rem;
   }
   @media (min-width: map_get($grid-breakpoints,'sm')) {
     font-size: 0.85rem;
   }
   color: $StormGray;
 }
  @at-root #header-nav-footer {
    ul, li  {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .level-name {
      font-weight: 600;
      color: $white;
    }
    .level-menu {
      color: darken($white,10%);
      .menu-item  {
        &:hover {
          color: $Razzmatazz;
        }
        &.active {
          color: $Razzmatazz;
        }
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}
#loader {
  background-color: rgba(255,255,255,0.5);
  position: absolute;
  left:0;right:0;
  top:0;
  bottom:0;
}