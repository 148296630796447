
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'),
  url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Light.woff') format('woff'),
  url('../fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Medium.woff') format('woff'),
  url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Bold.woff') format('woff'),
  url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraBold.eot');
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
  url('../fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
  url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


