/*
import Bootstrap
 */

$grid-columns: 24;
$grid-gutter-width: 30px;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

$container-max-widths: (
        sm: 767px,
        md: 991px,
        lg: 1199px,
        xl: 1220px
) !default;

/* цвета */

$vulcan: #151722;
$Shark: #2F3238;
$Raven: #7F8289;
$Razzmatazz: #b41149;
$TorchRed: #ff1a4b;
$btnColor: rgb(230, 0, 80);
$Trout: #4b515f;
//$cc: #434a54;
$Mirage: #1b1f2c;
$tamarind: #391930;
$StormGray: #6c6e78;
$Mirage2: #1f222f; // text-page block-command bg color
$Accenct: #ccd1d9;
$Accenct2: #ebeff5;
//$ff: #b41149;
$colors: (
        "vulcan": $vulcan,
        "shark": $Shark,
        "raven": $Raven,
        "trout": $Trout,
        'slider': #f5f7fa,
        'price': #2e3240,
);
$theme-colors: (
        "vulcan": $vulcan,
        "brand": $Razzmatazz,
        "link": $TorchRed,
        "button":$btnColor,
        "button2":$Raven,
        "navigat": $Mirage,
        "company": $Mirage2,
        "accent": $Accenct2,
        'slider': #f5f7fa,
);
$sizes: (
        10: 10,
        20: 20%,
        30: 30%,
        40: 40%,
        50: 50%,
        60: 60%,
        70: 70%,
        80: 80%,
        90: 90%,
        100: 100%,
        auto: auto
);
$body-bg: $Shark;
$body-color: $Raven;
$font-weight-base: 300; // 300
$font-weight-bold: 600;
$font-size-base: 0.90rem;
$font-family-base: 'Montserrat';

$h6-font-size: $font-size-base * 1.15;

$link-color: $TorchRed;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

$navbar-toggler-padding-y: .25rem;
$navbar-toggler-padding-x: .75rem;

$dropdown-bg: #1b1f2c;
$dropdown-border-radius: 0;
$dropdown-border-color: #1b1f2c;

$close-color: $TorchRed;
$close-text-shadow: none;

$carousel-control-color: $Raven;
$carousel-control-width: 5rem;
$carousel-indicator-width: 8px;
$carousel-indicator-height: 8px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $Razzmatazz;

$enable-rounded: false;

$pagination-active-bg: $Razzmatazz;
$pagination-focus-box-shadow: none;
$component-active-bg: $Razzmatazz;
$input-btn-focus-box-shadow:none;
//$input-focus-bg:                        $input-bg !default;
//$input-focus-border-color:              lighten($Razzmatazz, 25%) !default;
//$input-focus-color:                     $input-color !default;
//$input-focus-width:                     $input-btn-focus-width !default;
//$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

@import "~bootstrap/scss/bootstrap";


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

@import "fontawesome/fontawesome";
@import "fontawesome/fa-solid";
@import "fontawesome/fa-regular";
@import "fontawesome/fa-brands";

@import "krslon/defaults";
@import "krslon/fonts";
@import "krslon/icons";
@import "krslon/header";
@import "krslon/footer";
@import "krslon/text-page";
@import "krslon/navigation";
@import "krslon/catalogue";
@import "krslon/news";
@import "krslon/faq";
@import "krslon/feedback";
@import "krslon/article";
@import "krslon/price";
@import "krslon/examples";

@import "krslon/floating-label";
@import "krslon/slider";

@import "krslon/nouislider";

@import "~lightbox2/src/css/lightbox.css";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";