.article {
  &-image {
    height: 250px;
    overflow: hidden;
    img {
      min-width:100%;
      //width: 100%;
      object-fit: cover;
    }
  }
}