
.text-page {

  ul:not([class*='nc-toolbar']) {
    text-align: left;
    margin-left: 0.8rem;
    padding-left: 0;
    li {
      list-style-type: none;
    }
    li:before {
      content: "•";
      font-size: 22px;
      font-family: "Myraid Pro";
      color: $Razzmatazz;
      margin-left: -0.6em;
      display: inline-block;
      width: 0.6em;
      float: left;
      line-height: 1.5 * $font-size-base;
    }
  }

  &--block-command {

    /*background-image: url('../img/17_00000_2-2.png');*/
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 130%;

    @media (min-width: map_get($grid-breakpoints, sm)) {
      background-position: center top;
      background-size: 130%;
      max-height: 720px;
    }
    @media (min-width: map_get($grid-breakpoints, md)) {
      background-position: center 20px;
      background-size: 120%;
      max-height: 720px;

    }
    @media (min-width: map_get($grid-breakpoints, xl)) {
      background-position: center top;
      max-height: 720px;
      background-size: 100%;
    }
    .text {
      font-size: 1.2rem;
      @media (min-width: map_get($grid-breakpoints, md)) {
        font-weight: bold;
      }
    }
    .photo {
      min-height: 200px;
      //max-height: 300px;
      @media (min-width: map_get($grid-breakpoints, sm)) {
        //max-height: 350px;
      }
      @media (min-width: map_get($grid-breakpoints, md)) {
        max-height: 420px;
      }
      @media (min-width: map_get($grid-breakpoints, xl)) {
        //max-height: 500px;
      }
      //width: 100vw;
      height: (100vw/1.6);
      position: relative;
    }
    .person {
      &-label {
        position: absolute;
        background-image: url("../img/person-label.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        color: $white;

        padding-left: 10px;
        font-size: 0.75rem;
        $personW: 230px;
        width: $personW;
        height: $personW/1.8;
        @media (min-width: map_get($grid-breakpoints, md)) {
          font-size: 0.8rem;
          $personW: 250px;
          width: $personW;
          height: $personW/1.8;
        }
        p {
          margin: 0;
        }
      }
      &-techdir {
        bottom: 80px;
        left: -15px;
        @media (min-width: map_get($grid-breakpoints, md)) {
          left: 5%;
          bottom: 80px;
        }
        @media (min-width: map_get($grid-breakpoints, lg)) {
          left: 5%;
          bottom: 0px;
        }
        @media (min-width: map_get($grid-breakpoints, xl)) {
          left: 10%;
          bottom: 0px;
        }
      }
      &-buh {
        bottom: -10px;
        left: 20%;
        @media (min-width: map_get($grid-breakpoints, md)) {
          left: 27%;
        }
        @media (min-width: map_get($grid-breakpoints, lg)) {
          left: 27%;
          bottom: 0;
        }
        @media (min-width: map_get($grid-breakpoints, xl)) {
          bottom: 0;
          left: auto;
          right: 50%;
        }
      }
      &-gendir {
        bottom: 85px;
        right: 20%;
        @media (min-width: map_get($grid-breakpoints, md)) {
          right: 25%;
        }
        @media (min-width: map_get($grid-breakpoints, lg)) {
          right: 27%;
          bottom: 0px;
        }
        @media (min-width: map_get($grid-breakpoints, xl)) {
          right: 30%;
          bottom: 0px;
        }
      }
      &-commdir {
        bottom: -10px;
        right: -10px;
        @media (min-width: map_get($grid-breakpoints, md)) {
          right: 5%;
        }
        @media (min-width: map_get($grid-breakpoints, lg)) {
          right: 5%;
          bottom: 0px;
        }
        @media (min-width: map_get($grid-breakpoints, xl)) {
          right: 10%;
          bottom: 0px;
        }
      }
    }
  }

  &--block-icon-name-text {

    .textBefore {
      //color: lighten($Raven, 5%);
      //
      //font-size: 1.4rem;
      //@media (min-width: map_get($grid-breakpoints, sm)) {
      //  font-size: 1.6rem;
      //}
      //@media (min-width: map_get($grid-breakpoints, lg)) {
      //  font-size: 1.8rem;
      //}

    }
    .item {
      h5 {
        margin: 0;
      }
      p {
        margin: 0;
        padding: 0;
      }
    }
    .textAfter {
      color: $Razzmatazz;
      font-weight: 500;
      font-size: 1rem;
      //font-size: 0.8rem;
      @media (min-width: map_get($grid-breakpoints, sm)) {
        //font-size: 0.9rem;
      }
      @media (min-width: map_get($grid-breakpoints, lg)) {
        //font-size: 1rem;
      }
      ul {
        display: flex;
        //align-items: center;

        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        @media (min-width: map_get($grid-breakpoints, sm)) {
          flex-direction: row;
          flex-wrap: wrap;
        }
        @media (min-width: map_get($grid-breakpoints, md)) {
          justify-content: center;
        }
        li {
          display: inline-flex;
          margin-right: 2rem;
        }
      }
    }
  }
  &--block-icon {
    font-size: 1.0rem;

  }
  &--block-photo-text-button {

    .photo {
      img {

        //width: 70%;
        @media (min-width: map_get($grid-breakpoints, sm)) {
          //width: 70%;
        }
        @media (min-width: map_get($grid-breakpoints, md)) {
          //width: 80%;
        }
        @media (min-width: map_get($grid-breakpoints, lg)) {
          //max-width: 630px;
          //width: 85%;
        }
        @media (min-width: map_get($grid-breakpoints, xl)) {
          //width: 100%;
        }
      }
    }

    .text {
      //font-size: 1.2rem;

      .bigLabel {
        background-image: url('../img/label.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        width: 290px;
        height: 190px;

        font-size: 1.05rem;
        line-height: 1.2;
        font-weight: 600;

        display: flex;

        align-items: center;
        justify-content: center;

        padding-left: 50px;
        padding-top: 43px;

        padding-right: 40px;
        padding-bottom: 63px;

        margin-top: -50px;
        margin-bottom: -25px;

        color: $white;

        //@at-root .bg-white .bigLabel {
        //  color: $white;
        //}
        @media (min-width: map_get($grid-breakpoints, sm)) {
          margin-top: 0px;
          width: 361px;
          height: 212px;
          padding-left: 70px;
          padding-right: 70px;
          padding-top: 40px;
          padding-bottom: 68px;
        }
        @media (min-width: map_get($grid-breakpoints, sm)) {
          margin-left: -30px;
        }
        @media (min-width: map_get($grid-breakpoints, lg)) {
          padding-left: 105px;
          padding-right: 95px;
          padding-top: 50px;
          padding-bottom: 83px;
          width: 461px;
          height: 270px;
          font-size: 1.5rem;
        }
      }

      .price {
        font-weight: 700;
        color: $Razzmatazz;
        & b,
        & strong {
          @extend .price;
        }
      }
    }

  }

  &--block-text {

    .pageTitle {
      position: relative;
      margin-bottom: 1.5rem;
      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 40%;
        right: 40%;
        width: 20%;
        height: 3px;

        @extend .bg-gradient-horizontal;
      }
    }
    .pageText {
      h1 {
        position: relative;
        padding-left: 0.5rem;
        &:before {
          content: "";
          position: absolute;
          left: 0px;
          top: 10%;
          bottom: 10%;
          width: 3px;

          @extend .bg-gradient-vertical;
        }
        @extend .h3;
      }
    }
  }
  &--block-slider {

    .title {
      position: relative;
      margin-bottom: 1.5rem;
      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 40%;
        right: 40%;
        width: 20%;
        height: 3px;

        @extend .bg-gradient-horizontal;
      }
    }

    .photo {
      width: 270px;
      height: 270px;
      padding: 8%;
      @media (min-width: map_get($grid-breakpoints, sm)) {
        min-width: 300px;
        width: 300px;
        height: 300px;
      }
      @media (min-width: map_get($grid-breakpoints, md)) {
        min-width: 360px;
        height: 360px;
        padding: 5%;
      }
      @media (min-width: map_get($grid-breakpoints, lg)) {
        min-width: 360px;
        height: 360px;
        padding: 4%;
      }
      @media (min-width: map_get($grid-breakpoints, xl)) {
        min-width: 420px;
        height: 420px;
        padding: 4%;
      }
      img {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    .text {

    }
    .carousel-indicators {
      li {
        width: 8px;
        height: 8px;
      }
    }
    @media (min-width: map_get($grid-breakpoints, md)) {
      //.carousel-control-prev {
      //  left: -25px;
      //}
      //.carousel-control-next {
      //  right: -20px;
      //}
    }
    [class^='carousel-control'] {
      z-index: 999;
      display: none !important;
    }
    //.carousel-control-prev {
    //  left: -2rem;
    //}
    //.carousel-control-next {
    //  right: -2rem;
    //}
    @media (max-width: map_get($grid-breakpoints, md)) {
      [class^='carousel-control'] {
        display: flex !important;
        align-items: flex-start;
      }
      .carousel-control-prev {
        top:240px;
        justify-content: flex-start;
      }
      .carousel-control-next {
        top:240px;
        justify-content: flex-end;
      }
    }
    @media (min-width: map_get($grid-breakpoints, md)) {
      .carousel-control-prev {
        left: -2.5rem;
      }
      .carousel-control-next {
        right: -2.5rem;
      }
      top: 170px;
    }
    @media (min-width: map_get($grid-breakpoints, lg)) {
      .carousel-control-prev {
        left: -2rem;
      }
      .carousel-control-next {
        right: -2rem;
      }
      top: 200px;
    }

    &:hover {
      [class^='carousel-control'] {
        display: flex !important;
      }
    }
  }

  &--block-with-header {
    .title {
      position: relative;
      margin-bottom: 1.5rem;
      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 40%;
        right: 40%;
        width: 20%;
        height: 3px;

        @extend .bg-gradient-horizontal;
      }
      &.full-width {
        &:after {
          left: 0%;
          right: 0%;
          width: 100%;
        }
      }
      &.short {
        &:after {
          left: 50%;
          margin-left: -50px;
          right: auto;
          width: 100px;
        }
      }
    }
    .text {
      color: $Trout;
    }
  }
  &--block-with-column {
    .title {
      position: relative;
      margin-bottom: 1.5rem;
      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 40%;
        right: 40%;
        width: 20%;
        height: 3px;

        @extend .bg-gradient-horizontal;
      }
    }
    .text {
      color: $Trout;
      display: inline-block;
      text-align: justify;
      @media (min-width: map_get($grid-breakpoints, md)) {

        //-webkit-column-width: calc();
        //-moz-column-width: 200px;
        //column-width: 200px;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
        //-webkit-column-rule: 1px solid #ccc;
        //-moz-column-rule: 1px solid #ccc;
        //column-rule: 1px solid #ccc;
      }
    }
  }
  &--promo-block {
    background-color: $Mirage2;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;

    padding-top: 40%;

    @media (min-width: map_get($grid-breakpoints, md)) {
      background-size: cover;
      padding-top: 5%;
      background-position: center center;

    }
    @media (min-width: map_get($grid-breakpoints, xl)) {
      padding-top: 5%;
      background-position: bottom center;
      max-height: 100vh;
    }
    .label {
      color: $white;
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;
      z-index: 5;
      @media (min-width: 400px) {
      }
      @media (min-width: map_get($grid-breakpoints, md)) {
      }
      @media (min-width: map_get($grid-breakpoints, lg)) {
        margin-bottom: -110px;
      }
      &-content {
        background-image: url("../img/promo_label_320.png");
        background-position: center;
        background-repeat: no-repeat;

        min-width: 320px;
        width:100%;

        @media (max-width: map_get($grid-breakpoints, sm)) {
          margin-top: -10px;
          margin-bottom: -30px;

          background-size: cover;
          padding-top: 40px;
          padding-bottom: 100px;
          padding-left: 5%;
          padding-right: 5%;
          text-align: left;
        }
        //@media (max-width: (map_get($grid-breakpoints, sm) - 130px)) {
        //  margin-top: 0px;
        //}
        //@media (min-width:  (map_get($grid-breakpoints, sm) - 129px) and (max-width: map_get($grid-breakpoints, sm))) {
        //  margin-top: -10px;
        //}
        @media (min-width:map_get($grid-breakpoints, 'sm')) {
          background-image: url("../img/promo-label.png");
        }
        @media (min-width: map_get($grid-breakpoints, sm)) {
          width: 100%;
          position: relative;
          padding-top: 48.81%; /* (349:715)*100% */
          &-text {
            position: absolute; /* абсолютное положение элемент */
            top: 25%;
            left: 20%;
            right: 12%;
            bottom: 0;
          }
          &:before {
            display: block; /* отображать элемент как блок */
            content: ""; /* содержимое псевдоэлемента */
            width: 100%; /* ширина элемента */
          }
        }
        @media (min-width: map_get($grid-breakpoints, md)) {
          //background-size: cover;
          //
          width: 720px;
          &-text {
            position: absolute; /* абсолютное положение элемент */
            top: 25%;
            left: 23%;
            right: 12%;
            bottom: 0;
          }
        }
        @media (min-width: map_get($grid-breakpoints, lg)) {
          &-text {
            top: 30%;
          }
        }
        @media (min-width: map_get($grid-breakpoints, xl)) {
          &-text {
            top: 35%;
          }
        }
      }

      &-title {
        margin-bottom: 0.9rem;
        //font-size: 70% !important;
        @media (min-width: map_get($grid-breakpoints, md)) {
          //font-size: 1.6rem;
          margin-left: -5%;
          margin-right: -10%;
        }
      }
      &-text {
        text-transform: uppercase;
        font-size: 0.7rem;
        @media (min-width: map_get($grid-breakpoints, md)) {
          font-size: 0.8rem;
        }
        li {
          margin-bottom: 0.3rem;
        }
        .fa-ul {
          margin-left: 1.5em;
          margin-bottom: 0;
          .fa-li {
            left: -1.5em;
            width: 1.3em;

          }
        }
      }
    }
    .action {
      border-radius: 50%;
      width: 140px;
      height: 140px;
      padding: 15px;
      font-size: 0.95rem;
      position: relative;
      z-index: 15;
      color: #1b1f2c;
      -moz-transform: rotate(15deg);
      -webkit-transform: rotate(15deg);
      -o-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg);
      b, strong {
        font-weight: 700;
      }
      margin-top: -80px;
      @media (min-width: map_get($grid-breakpoints, md)) {
        margin-left: 40%;
        margin-top: -120px;
        padding: 15px;
        //width: 140px;
        //height: 140px;
      }
      @media (min-width: map_get($grid-breakpoints, md)) {
        margin-left: 40%;
        margin-top: -140px;
      }
    }
    .button {
      position: relative;
      z-index: 25;
    }
  }
  &--block-photo-text {
    .photo {
      min-width: 40%;
    }
    .text {
      color: #e6e6e6;
      @media (min-width: map_get($grid-breakpoints, md)) {
        width: 60%;
        padding-right: 2rem;
      }

    }
  }

  &--block-allcategory {
    a {
      &:hover {
        color: $TorchRed !important;
      }
    }
  }
}

.brdGrad {

  position: relative;
  margin-bottom: 1rem;
  font-size: 1.15rem;

  @media (min-width: map_get($grid-breakpoints, sm)) {
    margin-top: 1rem;
    padding-left: 10px;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 10%;
    bottom: 10%;
    width: 0px;
    @extend .bg-gradient-vertical;
    @media (min-width: map_get($grid-breakpoints, sm)) {
      width: 3px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 35%;
    right: 35%;
    width: 30%;
    height: 3px;

    @extend .bg-gradient-horizontal;
    @media (min-width: map_get($grid-breakpoints, sm)) {
      height: 0;
    }
  }

  @media (min-width: map_get($grid-breakpoints, md)) {
    font-size: 1.15rem;
    //margin-top: 3rem;
  }
  @media (min-width: map_get($grid-breakpoints, lg)) {

  }
}

.photo-krug {
  background-image: url('../img/krug.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  img {
    background-color: $white;
    border-radius: 50%;
    //width: 80% !important;
    //height:80% !important;
  }
}

.bg-navigat .photo-krug {
  background-image: url('../img/krug-dark.png');
}

.promo-icons {

  @media (min-width: map_get($grid-breakpoints, md)) {
    background-color: rgba($Mirage, 0.7);
  }

  .item {
    color: $white;
    @media (min-width: map_get($grid-breakpoints, md)) {
      padding-right: 10px;
    }
  }
}

.columns4 {
  font-size: 0.8rem;
  @media (min-width: map_get($grid-breakpoints, sm)) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }

  @media (min-width: map_get($grid-breakpoints, md)) {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
}
