.faq {
  &-item {
    .far {
      font-weight:300;
    }
    .q {
      font-size: 1.1rem;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 1.2;
      padding-bottom: 1rem;
    }
    .a {
      padding-bottom: 1rem;
      font-weight: 300;
    }
    padding-bottom: 1.5rem;
  }
}