.navigationImageFlex {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .item {
    position: relative;
    overflow: hidden;
    height: 100px;
    @media (min-width: map_get($grid-breakpoints,md)) {
      height: 150px;
    }
    @media (min-width: map_get($grid-breakpoints,lg)) {
      height: 200px;
    }
    a {
      position: relative;
      img {
        opacity: 0.5;
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        transition: 1s;
        object-fit: cover;
        width: 100%;
        //height: 100%;
        //overflow: hidden;
      }
      div {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 5;
        span {
          color: $white;
          background: transparent;
          transition: 1s;
        }
      }

    }
    &:hover {
      img {
        opacity: 1;
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
      }
      div {
        span {
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff1a4b+0,800024+100 */
          background: rgb(255, 26, 75); /* Old browsers */
          background: -moz-linear-gradient(left, rgba(255, 26, 75, 1) 0%, rgba(128, 0, 36, 1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(255, 26, 75, 1) 0%, rgba(128, 0, 36, 1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, rgba(255, 26, 75, 1) 0%, rgba(128, 0, 36, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff1a4b', endColorstr='#800024', GradientType=1); /* IE6-9 */
        }
      }
    }
  }
}
.article-menu {
  li {
    &:hover {
      @extend .bg-accent;
    }
  }
}