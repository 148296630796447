.examples {
  //
  font-weight: 400;
  &-title {
    position: relative;
    margin-bottom: 1.5rem;
    &:after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 50%;
      //right: 40%;
      margin-left: -50px;
      width: 100px;
      height: 3px;

      @extend .bg-gradient-horizontal;
    }
  }
  .example {
    color: $dark;
    .title {
      @media (min-width: map_get($grid-breakpoints,'md')) {
        min-height: 62px;
      }

    }
  }
  &-image {
    //height: 180px;
    overflow: hidden;
    img {
      object-fit: cover;
    }
  }
  &-price {
    font-size: 1.2rem;
  }
  ul:not([class*='nc-toolbar']) {
    text-align: left;
    margin-left: 0.8rem;
    padding-left: 0;
    li {
      list-style-type: none;
    }
    li:before {
      content: "•";
      font-size: 22px;
      font-family: "Myraid Pro";
      color: $Razzmatazz;
      margin-left: -0.6em;
      display: inline-block;
      width: 0.6em;
      float: left;
      line-height: 1.5 * $font-size-base;
    }
  }
}