html, body {
  min-width: 320px !important;
  width: 100%;
}

b, strong {
  font-weight: $font-weight-bold;
}

.bg-topnav {
  background-color: $vulcan;
  border-bottom: 2px solid darken($Razzmatazz, 10%);
}

.bg-footer {
  background-color: $Mirage;
  border-bottom: 2px solid $tamarind;
}

.bg-company {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1a1d29+0,1a1a23+100 */
  background: rgb(26, 29, 41); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(26, 29, 41, 1) 0%, rgba(26, 26, 35, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(26, 29, 41, 1) 0%, rgba(26, 26, 35, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(26, 29, 41, 1) 0%, rgba(26, 26, 35, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a1d29', endColorstr='#1a1a23', GradientType=0); /* IE6-9 */
}

.bg-navigat,
.bg-topnav,
.bg-footer,
.bg-company {
  color: $Accenct;
}

.bg-gradient-horizontal {
  border:0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff1a4b+0,ff1a4b+33,b2003f+34,b2003f+66,7c0124+67,7c0124+100 */
  background: rgba(124, 1, 36, 1); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(255, 26, 75, 1) 0%, rgba(255, 26, 75, 1) 33%, rgba(178, 0, 63, 1) 33%, rgba(178, 0, 63, 1) 67%, rgba(124, 1, 36, 1) 67%, rgba(124, 1, 36, 1) 101%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 26, 75, 1) 0%, rgba(255, 26, 75, 1) 33%, rgba(178, 0, 63, 1) 33%, rgba(178, 0, 63, 1) 67%, rgba(124, 1, 36, 1) 67%, rgba(124, 1, 36, 1) 101%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 26, 75, 1) 0%, rgba(255, 26, 75, 1) 33%, rgba(178, 0, 63, 1) 33%, rgba(178, 0, 63, 1) 67%, rgba(124, 1, 36, 1) 67%, rgba(124, 1, 36, 1) 101%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff1a4b', endColorstr='#7c0124', GradientType=1); /* IE6-9 */
}

.bg-gradient-vertical {
  border:0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff1a4b+0,ff1a4b+33,b2003f+34,b2003f+66,7c0124+67,7c0124+100 */
  background: rgb(255, 26, 75); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(255, 26, 75, 1) 0%, rgba(255, 26, 75, 1) 33%, rgba(178, 0, 63, 1) 34%, rgba(178, 0, 63, 1) 66%, rgba(124, 1, 36, 1) 67%, rgba(124, 1, 36, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 26, 75, 1) 0%, rgba(255, 26, 75, 1) 33%, rgba(178, 0, 63, 1) 34%, rgba(178, 0, 63, 1) 66%, rgba(124, 1, 36, 1) 67%, rgba(124, 1, 36, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 26, 75, 1) 0%, rgba(255, 26, 75, 1) 33%, rgba(178, 0, 63, 1) 34%, rgba(178, 0, 63, 1) 66%, rgba(124, 1, 36, 1) 67%, rgba(124, 1, 36, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff1a4b', endColorstr='#7c0124', GradientType=0); /* IE6-9 */
}

.carousel-control-prev,
.carousel-control-next {
  font-size: 60px;
}

@media (max-width: map_get($grid-breakpoints, sm)) {
  h1, .h1 {
    font-size: 1.8rem;
  }
  h2, .h2 {
    font-size: 1.6rem;
  }
  h3, .h3 {
    font-size: 1.4rem;
  }
  h4, .h4 {
    font-size: 1.3rem;
  }
  h5, .h5 {
    font-size: 1.2rem;
  }
  h6, .h6 {
    font-size: 1.1rem;
  }

}

@media (max-width: map_get($grid-breakpoints, md)) {
  h6, .h6 {
    font-size: 1rem;
  }
}

.btn-gradient {
  @extend .bg-gradient-horizontal;
}

.btn {
  white-space: normal !important;
}


.header-block {

  min-height: 200px;

  &--height-auto {
    height: auto;
    min-height: 0;
  }

  &--Mirage2 {
    background-color: $Mirage2;
  }

  &--square {
    background-image: url("../img/header-bg--square.png");
    background-position: 0 0;
    background-repeat: repeat;
    @media  (max-width:map_get($grid-breakpoints,'sm')){
      background-size: cover;
    }
  }

  &-title {

    text-transform: uppercase;
    color: $white;
  }

  &-nav {
    color: $white;

    a {
      color: inherit;
    }
  }
}

#nc_modal_status > * {
  @extend .alert;
}

#nc_error {
  @extend .alert-danger;
}
