.catalogue {
  &-title {
    position: relative;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    &:after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 50%;
      //right: 40%;
      margin-left: -50px;
      width: 100px;
      height: 3px;

      @extend .bg-gradient-horizontal;
    }
    &.no-line {
      &:after {
        display: none;
      }
    }
  }


  .item {
    position: relative;
    max-height: 200px;
    overflow: hidden;
    z-index:1;
    @media (max-width: map_get($grid-breakpoints, sm)) {
      min-height: 120px;
    }

    .hover {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.8);
      color: $white;
      text-align: center;
      padding: 15%;
      display: none;
      z-index:3;
    }
    &:hover {
      .hover {
        display: flex;
      }
    }
    .name {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index:2;
      background-color: $Shark;
      span {
        color: $white;
      }
    }
  }
  &-material {
    .item {
      max-height: 100%;
      img {
        object-fit: contain;
        max-height: 200px;
        width: 100%;
      }
      .name {
        position: relative;
        background-color: $light;
        span {
          color: $dark;
        }
      }
      &:hover {
        .name {
          background-color: $Razzmatazz;
          span {
            color: $white
          }
        }
      }
    }
  }

  &-full {
    .slide {
      height: 100%;
      display:block !important;
      .image {
        height: 200px;
        height: 40vh;
        //@media (min-width: map_get($grid-breakpoints, sm)) {
        //  height: 300px;
        //}
        @media (min-width: map_get($grid-breakpoints, md)) {
          //height: 400px;
          height: 70vh;
        }
        @media (min-width: map_get($grid-breakpoints, lg)) {
          //height: 500px;
          height: 75vh;
        }
        //@media (min-width: map_get($grid-breakpoints, xl)) {
        //  height: 600px;
        //}
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .label {
      position: relative;
      background-image: url("../img/promo-label.png");
      background-position: 12px 0px;
      background-repeat: no-repeat;
      background-size: auto 100%;
      color: $white;
      text-align: center;

      top: -65px;
      padding-top: 23px;
      margin-bottom: -60px;
      height: 130px;
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      padding-left:20px;
      .name {
        font-size: 1.5rem;
      }
      @media (min-width: map_get($grid-breakpoints, lg)) {
        top: -145px;
        height: 170px;
        width: 400px;
        padding-top: 30px;
        margin-bottom: -150px;
        padding-left: 10px;
        .name {
          font-size: 1.8rem;
        }
        .price {
          font-size: 1.1rem;
        }
      }

    }
    .kitchen-title {
      font-size: 1.8rem;
      @media (min-width: map_get($grid-breakpoints, lg)) {
        font-size: 1.8rem;
        line-height: 1.5;
      }
    }
    .kitchen-subtitle {
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    .kitchen-params {
      font-size: 1rem;
      .title {
        margin-bottom: 1rem;
      }
      .param {

      }
      .dots {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0.2rem;
          right: 0.2rem;
          height: 0;
          bottom: 0.25rem;
          border-bottom: 1px dotted $body-color;
        }

      }
    }
  }
}

.filter-checkbox {
  input[type='checkbox'] {
    display: none;
    //    text-indent: -999999px;
    & + label {
      position: relative;
      padding-left: 30px;
      color: $dark;
      font-weight: 400;
      line-height: 17px;
      &:before {
        content: '';
        position: absolute;
        left: 0;

        background-image: url('../img/filter-checkbox.png');
        background-position: 0 -25px;
        background-repeat: no-repeat;
        width: 17px;
        height: 17px;
      }
    }
    &:checked + label {
      font-weight: 600;
      &:before {
        background-position-y: 0;
      }
    }
  }
}

input[type='radio'] {
  display: none;
  //    text-indent: -999999px;
  & + label {
    position: relative;
    padding-left: 30px;
    color: $dark;
    font-weight: 400;
    line-height: 17px;
    &:before {
      content: '';
      position: absolute;
      left: 0;

      background-image: url('../img/filter-radio.png');
      background-position: 0 -25px;
      background-repeat: no-repeat;
      width: 17px;
      height: 17px;
    }
  }
  &:checked + label {
    font-weight: 600;
    &:before {
      background-position-y: 0;
    }
  }
}

.colors {
  input {
    display: none;
  }
  input:checked + .color {
    border-color: $Razzmatazz;
    border-style: inset;
    border-width: 2px;
    opacity: 1;
  }
  .color {
    border: 1px solid $Raven;
    width: 20px;
    height: 20px;

    @at-root .nc-form & {
      width: 30px;
      height: 30px;
      margin: 3px;
    }
    opacity: 0.7;
  }
}