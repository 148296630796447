.header {
  &-nav {
    height: 64px;
    font-size: 0.8rem;
    position: relative;
    @at-root #header-nav-top {
      text-transform: uppercase;
      .nav-item {
        border-top:4px solid transparent;
        border-bottom:4px solid transparent;
        .nav-link {
          color: $white;
        }
        &:hover,
        &.active {
          border-bottom:4px solid $TorchRed;
        }
      }

    }
    @at-root #header-nav-side {
      position: absolute;
      left:0;
      top:0;
      @media  (max-width: map_get($grid-breakpoints,'sm')){
        right:0;
      }
      padding:45px 65px;
      @media  (max-device-height: 550px){
        padding:35px 35px;
      }



      font-size:1rem;
      //z-index: $zindex-dropdown;
      .close {
        font-size: 4rem !important;
        line-height: 1;
        font-weight: 300;
        position: absolute;
        right:20px;
        top:5px;
        height: 35px;width:35px;
      }
      ul, li  {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .level-name {
        font-weight: 600;
        color: $Trout;
      }
      .level-menu {
        color: darken($white,10%);
        .menu-item  {
          &:hover {
            color: $Razzmatazz;
          }
          &.active {
            color: $Razzmatazz;
          }
          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }
  &-phones {
    font-size: 0.8rem;
    line-height: 1.3;
    font-weight: 600;
    i {
      font-size:95%;
    }
  }
  &-email {
    font-size: 0.90rem;
    line-height: 1.3;
    font-weight: 600;
    i {
      font-size:95%;
    }
  }

}