.price {
  &-block {
    font-size: 1rem;
  }
  &-item {
    line-height: 1.1;
  }
  &-gradient {
    position: relative;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0%;
      right: 0%;
      //width: 20%;
      height: 3px;

      @extend .bg-gradient-horizontal;
    }
  }
}
.linksAfter {
  a {
    position: relative;
    &:before {
      @extend .far;
      content: fa-content($fa-var-file-pdf);
      margin-right:0.5rem;
    }
  }
}